<template>
  <div>
    <div>
      <div>
        <span class="text-color"> <b>Payor</b> </span> <br />
        <v-text-field value="JFS" disabled></v-text-field>
      </div>
      <div>
        <span class="text-color"> <b>Service</b> </span> <br />

        <v-select :items="services" v-model="service"> </v-select>
      </div>
      <div>
        <span class="text-color"> <b>Service Month</b> </span> <br />

        <v-select :items="getMonths" v-model="month"> </v-select>
      </div>
    </div>

    <div class="float-right mt-10">
      <span>
        <a @click="RESET" class="btn btn-danger btn-sm">Cancel</a>
      </span>
      <span class="m-4">
        <a @click="Apply" class="btn btn-success btn-sm">Export JFS Report
          <span v-if="isRemoving">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </span>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  name: "overtimeReport",

  mixins: [validationMixin],
  validations: {
    form: {},
  },
  data() {
    return {
      datesofmonths: [],
      selectedmonthreport: [],
      isRemoving: false,
      form: {
        payersId: "bda018ce-413d-4307-6084-08da560cdcad",
        clientsId: null,

        dates: {
          startDate: null,
          endDate: null,
        },
      },
      services: ["MSSP", "Holocaust", "WHCSC"],
      menu: false,
      month: "",
      service: "",
      loader: false,
    };
  },
  computed: {
    ...mapGetters([
      "PayersList",
      "ClientsList",
      "BillingReport",
      "InactivityReport",
      "JFSReport",
    ]),
    computedDate() {
      return new Date(this.form.date).setDate(
        new Date(this.form.date).getDate() - 1
      );
    },
    formatDate2() {
      if (!this.form.date) return null;

      const [year, month, day] = this.form.date.split("-");
      return `${month}/${day}/${year}`;
    },
    ScheduleDate() {
      if (!this.form.date) return null;
      const [year, month, day] = this.form.date.split("-");
      return `${month}/${day}/${year}`;
    },
    getMonths() {
      let month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      var date = new Date();

      var arr = [];

      for (var i = 0; i < 6; i++) {
        if (date.getMonth() - i >= 0) {
          arr.push(month[date.getMonth() - i] + " " + date.getFullYear());
        } else {
          arr.push(
            month[12 + (date.getMonth() - i)] + " " + (date.getFullYear() - 1)
          );
        }
      }

      return arr;
    },
  },

  mounted() {
    this.$store.dispatch("getPayersList");
  },
  methods: {
    SetDateByWeek(data) {
      data.clickApply();
      if (this.form.dates.startDate.getDay() > 0) {
        this.form.dates.startDate.setDate(
          new Date(
            this.form.dates.startDate.getDate() -
            this.form.dates.startDate.getDay()
          )
        );
        this.form.dates.endDate = new Date(
          this.form.dates.endDate.setMonth(this.form.dates.startDate.getMonth())
        ).setDate(this.form.dates.startDate.getDate() + 6);
      }
    },

    getWeekNumber(dateString) {
      const date = new Date(dateString);
      const dayOfMonth = date.getDate();
      const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const daysUntilTarget = dayOfMonth + firstDayOfMonth.getDay() - 1;
      const weekOfMonth = Math.ceil(daysUntilTarget / 7);

      return weekOfMonth;
    },
    // getWeekNumber(date) {
    //   var startOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
    //   var daysInMonth = date.getDate();
    //   var daysInFirstWeek = Math.max(0, 7 - (startOfWeek.getDate() - startOfWeek.getMonth()));

    //   var used = daysInMonth - daysInFirstWeek;
    //   var weekNumber = Math.ceil(used / 7) + 1;

    //   return weekNumber;
    // },

    ExportBilling() {
      var data = [];
      var header = "";
      var rows = "";
      var filtered = [];
      var weeks = this.weekCount(
        new Date(this.JFSReport[0].startTimeDate).getFullYear(),
        parseInt(this.JFSReport[0].startTimeDate.split("-")[1].split("T")[0])
      );
      this.JFSReport.forEach((x) => {
        if (
          filtered.find((y) => y.clientId === x.clientId && y.subCodeId === x.subCodeId) == null
        ) {
          filtered.push(x);
        }
      });
      filtered = filtered.sort((a, b) =>
        a.clientName > b.clientName ? 1 : -1
      );

      filtered.forEach((e, i) => {
        var serviceDate = "";
        var workedHour = 0;
        var temp = this.JFSReport.filter(
          (x) => x.clientId === e.clientId && x.subCodeId === e.subCodeId
        );

        temp.forEach((y) => {
          if (
            !serviceDate
              .split(",")
              .includes(y.startTimeDate.split("-")[2].split("T")[0])
          ) {
            serviceDate += y.startTimeDate.split("-")[2].split("T")[0] + ",";
          }
          workedHour += parseFloat(y.actualHours);
        });
        var weekDays = [];
        temp.forEach((z) => {
          if (
            weekDays.find(
              (t) =>
                t.week === this.getWeekNumber(z.startTimeDate),//Math.ceil(new Date(z.startTimeDate).getDate() / 7)
            ) == null
          ) {
            var obj = {
              week: this.getWeekNumber(z.startTimeDate),// Math.ceil(new Date(z.startTimeDate).getDate() / 7),
              days: new Date(z.startTimeDate).getDate() + ",",
              hours: parseFloat(z.actualHours),
            };
            weekDays.push(obj);
          } else {
            weekDays = weekDays.map((t) => {
              if (
                t.week == this.getWeekNumber(z.startTimeDate) // Math.ceil(new Date(z.startTimeDate).getDate() / 7)
              ) {
                t.days += new Date(z.startTimeDate).getDate() + ",";
                t.hours += parseFloat(z.actualHours);
              }
              return t;
            });
          }
        });
        rows += `<tr>`;
        rows += `<td>${i + 1}</td>`;
        rows += `<td>${e.clientName}</td>`;
        rows += `<td>${e.caregiverName[e.caregiverName.length - 1] == ","
          ? e.caregiverName.substring(0, e.caregiverName.length - 2)
          : e.caregiverName
          }</td>`;
        rows += `<td>${e.subCode}</td>`;
        rows += `<td style="text-align:center;"></td>`;
        for (var j = 0; j < weeks; j++) {
          var weekd = weekDays.find((x) => x.week == j + 1);
          if (weekd != null && weekd != undefined) {
            rows += `<td style="text-align:right;" >${weekd.days.slice(0, -1)}</td>`;
            rows += `<td style="text-align:right;" >${weekd.hours}</td>`;
          } else {
            rows += `<td style="text-align:right;" ></td>`;
            rows += `<td style="text-align:right;" >0</td>`;
          }
        }
        rows += `<td>${workedHour}</td>`;
        rows += `<td></td>`;
        rows += `<td>$${parseFloat(e.caregiverRate).toFixed(2)}</td>`;
        rows += `<td>$${parseFloat(
          parseFloat(workedHour) * parseFloat(e.caregiverRate)
        ).toFixed(2)}</td>`;
        rows += `<td>${e.hireDate != null ? new Date(e.hireDate).toLocaleString() : ""
          }</td>`;
        rows += `<td>${e.lastVisitDate != null
          ? new Date(e.lastVisitDate).toLocaleString()
          : ""
          }</td>`;
        rows += `</tr>`;
      });

      var month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      var test = `
          <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>
          <head>
          <xml>
          <x:ExcelWorkbook>
          <x:ExcelWorksheets>
          <x:ExcelWorksheet>
          <x:Name>Sheet 1</x:Name>
          <x:WorksheetOptions>
          <x:Print>
          <x:ValidPrinterInfo/>
          </x:Print>
          </x:WorksheetOptions>
          </x:ExcelWorksheet>
          </x:ExcelWorksheets>
          </x:ExcelWorkbook>
          </xml>
          </head>
          <body>
          <table>
            <tr>
              <td ></td>
              <td style="text-align:center;font-weight:bold;">Choice Home Care, Inc.</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">14101 Valleyheart Dr. #200</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">Sherman Oaks, CA 91423</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">T. (818) 894-4151</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">F. (818) 894-4947 </td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;"></td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">330 North Fairfax Ave.</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">Los Angeles, CA 90036</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">Tel. # 323/556-2938</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;"></td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">Invoice for the month of  ${month[
        parseInt(
          this.JFSReport[0].startTimeDate.split("-")[1].split("T")[0]
        ) - 1
        ]
        }, 2023</td>
            </tr>
      <tr>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">#</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Client Name</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Case Worker</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Type of Service</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;"># of Miles</td>
        `;
      for (var i = 0; i < weeks; i++) {
        test += `<td style="margin:0;text-align:center;border:1px solid black;font-weight:bold">Week ${i + 1
          }  Dates of Service</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold">Hours</td>`;
      }
      test += `
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;"># of Hours</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Heavy Clng</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Hourly Rate</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Total</td>

      </tr>
      ${rows}
    </table>
    </body>
    </html>`;

      this.downloadCSVFile2(test, `JFS_${this.service}_${this.month}`);
    },
    ExportBillingHollo() {
      var data = [];
      var header = "";
      var rows = "";
      var filtered = [];
      var weeks = 1
      // this.weekCount(
      //   new Date(this.JFSReport[0].startTimeDate).getFullYear(),
      //   parseInt(this.JFSReport[0].startTimeDate.split("-")[1].split("T")[0])
      // );
      this.JFSReport.forEach((x) => {
        if (
          filtered.find((y) => y.clientId === x.clientId && y.subCodeId === x.subCodeId) == null
        ) {
          filtered.push(x);
        }
      });
      filtered = filtered.sort((a, b) =>
        a.clientName > b.clientName ? 1 : -1
      );

      filtered.forEach((e, i) => {
        var serviceDate = "";
        var workedHour = 0;
        var temp = this.JFSReport.filter(
          (x) => x.clientId === e.clientId && x.subCodeId === e.subCodeId
        );

        temp.forEach((y) => {
          if (
            !serviceDate
              .split(",")
              .includes(y.startTimeDate.split("-")[2].split("T")[0])
          ) {
            serviceDate += y.startTimeDate.split("-")[2].split("T")[0] + ",";
          }
          workedHour += parseFloat(y.actualHours);
        });
        var weekDays = [];
        temp.forEach((z) => {
          if (
            weekDays.find(
              (t) =>
                t.week === 1 //this.getWeekNumber(z.startTimeDate) ,//Math.ceil(new Date(z.startTimeDate).getDate() / 7)
            ) == null
          ) {
            var obj = {
              week: 1, //this.getWeekNumber(z.startTimeDate),// Math.ceil(new Date(z.startTimeDate).getDate() / 7),
              days: new Date(z.startTimeDate).getDate() + ",",
              hours: parseFloat(z.actualHours),
            };
            weekDays.push(obj);
          } else {
            weekDays = weekDays.map((t) => {
              if (
                t.week == 1//this.getWeekNumber(z.startTimeDate) // Math.ceil(new Date(z.startTimeDate).getDate() / 7)
              ) {
                t.days += new Date(z.startTimeDate).getDate() + ",";
                t.hours += parseFloat(z.actualHours);
              }
              return t;
            });
          }
        });
        rows += `<tr>`;
        rows += `<td>${i + 1}</td>`;
        rows += `<td>${e.clientName}</td>`;
        rows += `<td>${e.caregiverName[e.caregiverName.length - 1] == ","
          ? e.caregiverName.substring(0, e.caregiverName.length - 2)
          : e.caregiverName
          }</td>`;
        rows += `<td>${e.subCode}</td>`;
        rows += `<td style="text-align:center;"></td>`;
        // for (var j = 0; j < weeks; j++) {
        var weekd = weekDays.find((x) => x.week == 1);
        if (weekd != null && weekd != undefined) {
          rows += `<td style="text-align:right;" >${weekd.days.slice(0, -1)}</td>`;
          rows += `<td style="text-align:right;" >${weekd.hours}</td>`;
        } else {
          rows += `<td style="text-align:right;" ></td>`;
          rows += `<td style="text-align:right;" >0</td>`;
        }
        // }
        rows += `<td>${workedHour}</td>`;
        rows += `<td></td>`;
        rows += `<td>$${parseFloat(e.caregiverRate).toFixed(2)}</td>`;
        rows += `<td>$${parseFloat(
          parseFloat(workedHour) * parseFloat(e.caregiverRate)
        ).toFixed(2)}</td>`;
        rows += `<td>${e.hireDate != null ? new Date(e.hireDate).toLocaleString() : ""
          }</td>`;
        rows += `<td>${e.lastVisitDate != null
          ? new Date(e.lastVisitDate).toLocaleString()
          : ""
          }</td>`;
        rows += `</tr>`;
      });

      var month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      var test = `
          <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>
          <head>
          <xml>
          <x:ExcelWorkbook>
          <x:ExcelWorksheets>
          <x:ExcelWorksheet>
          <x:Name>Sheet 1</x:Name>
          <x:WorksheetOptions>
          <x:Print>
          <x:ValidPrinterInfo/>
          </x:Print>
          </x:WorksheetOptions>
          </x:ExcelWorksheet>
          </x:ExcelWorksheets>
          </x:ExcelWorkbook>
          </xml>
          </head>
          <body>
          <table>
            <tr>
              <td ></td>
              <td style="text-align:center;font-weight:bold;">Choice Home Care, Inc.</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">14101 Valleyheart Dr. #200</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">Sherman Oaks, CA 91423</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">T. (818) 894-4151</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">F. (818) 894-4947 </td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;"></td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">330 North Fairfax Ave.</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">Los Angeles, CA 90036</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">Tel. # 323/556-2938</td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;"></td>
            </tr>
            <tr>
              <td ></td>
              <td style="margin:0;text-align:center;font-weight:bold;">Invoice for the month of  ${month[
        parseInt(
          this.JFSReport[0].startTimeDate.split("-")[1].split("T")[0]
        ) - 1
        ]
        }, 2023</td>
            </tr>
      <tr>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">#</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Client Name</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Case Worker</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Type of Service</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;"># of Miles</td>
        `;
      for (var i = 0; i < weeks; i++) {
        test += `<td style="margin:0;text-align:center;border:1px solid black;font-weight:bold">Dates of Service</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold">Hours</td>`;
      }
      test += `
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;"># of Hours</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Heavy Clng</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Hourly Rate</td>
        <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Total</td>

      </tr>
      ${rows}
    </table>
    </body>
    </html>`;

      this.downloadCSVFile2(test, `JFS_${this.service}_${this.month}`);
    },
    downloadCSVFile2(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;
      var base64data = "base64," + btoa(csv);
      //window.open('data:application/vnd.ms-'+defaults.type+';filename=exportData.doc;' + base64data); //this was original

      // Now the little tricky part.(By shreedhar)
      // you can use either>> window.open(uri);
      // but this will not work in some browsers
      // or you will not get the correct file extension
      var uri = "data:application/vnd.ms-excel;" + base64data;

      // download_link.href = window.URL.createObjectURL(csv_file);
      download_link.href = uri;

      download_link.style = "visibility:hidden";

      document.body.appendChild(download_link);

      download_link.click();
    },
    downloadCSVFile(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;

      download_link.href = window.URL.createObjectURL(csv_file);

      download_link.style.display = "none";

      document.body.appendChild(download_link);

      download_link.click();
    },
    ActualHours(obj) {
      var o = this.BillingReport.hours.find((x) => x.clientId === obj.clientID && x.subCodeId === obj.subCodeId);
      return o.hours;
    },
    clearRange() {
      this.form.dates = {
        startDate: null,
        endDate: null,
      };
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    selectedDates() {
      return this.datesofmonths.toString();
    },
    weekCount(year, month_number) {
      // month_number is in the range 1..12

      // var firstOfMonth = new Date(year, month_number - 1, 1);
      // var lastOfMonth = new Date(year, month_number, 0);

      // var used = firstOfMonth.getDay() + lastOfMonth.getDate();

      // return Math.ceil(used / 7);

      var firstOfMonth = new Date(year, month_number - 1, 1);
      var lastOfMonth = new Date(year, month_number, 0);

      // Calculate the number of days from the first day of the month to the end of the month
      var daysInMonth = lastOfMonth.getDate();

      // Calculate the number of days in the first week (from the first day to the end of the week)
      var daysInFirstWeek = 7 - firstOfMonth.getDay();

      // Subtract the days in the first week from the total days to get the correct count for the last week
      var used = daysInMonth - daysInFirstWeek;

      // Add 1 to include the first week
      return Math.ceil(used / 7) + 1;
    },
    showHoursYearly(sobj, eobj, obj) {
      var hoursData = [];
      for (var d = new Date(sobj); d <= new Date(eobj);) {
        if (d.getFullYear() == new Date(obj.startTimeDate).getFullYear()) {
          if (d.getMonth() == new Date(obj.startTimeDate).getMonth()) {
            hoursData.push(obj.actualHours);
          } else {
            hoursData.push(0);
          }
        } else {
          hoursData.push(0);
        }
        d = new Date(d.setMonth(d.getMonth() + 1));
      }
      return hoursData.toString();
    },
    selectedMonth(s, e) {
      var month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var datemont = [];
      if (s.getFullYear() == e.getFullYear()) {
        var datemont = [];
        var selectedmonths = month.slice(s.getMonth(), e.getMonth() + 1);
        selectedmonths.forEach(function (x) {
          datemont.push(0);
        });
        this.selectedmonthreport = selectedmonths;
        this.datesofmonths = datemont;
        return selectedmonths.toString();
      } else {
        for (var d = new Date(s); d <= new Date(e);) {
          datemont.push(month[d.getMonth()] + " " + d.getFullYear());

          d = new Date(d.setMonth(d.getMonth() + 1));
        }
        this.datesofmonths = datemont;
        return datemont.toString();
      }
    },
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    async Apply() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.loader = true;

        this.isRemoving = true;
        await this.$store
          .dispatch("GetJFSUserReport", {
            Month: this.month,
            Service: this.service,
          })
          .then((response) => {
            this.isRemoving = false;
            this.isfetching = false;
            if (response.length > 0) {
              if (this.service == "MSSP") {
                this.ExportBillingHollo()
              } else {

                this.ExportBilling();
              }
              this.$bvModal.hide("filter");
            } else {
              this.loader = false;
              Swal.fire({
                title: "",
                text: "No Record Found",
                icon: "error",
                confirmButtonClass: "btn btn-danger",
              });
            }
          })
          .catch((ex) => {
            this.isfetching = false;
          });
      }
    },
    RESET() {
      (this.form.batchName = []),
        (this.form.date = []),
        (this.form.dates = {}),
        this.$bvModal.hide("overfileForm");
    },
  },
};
</script>

<style>
.v-date-picker-table .v-btn.v-btn--active {
  color: #2d79bd !important;
}

.v-btn--outlined {
  border: thin solid #2d79bd !important;
}

.text-color {
  color: #2d79bd;
}

.reportrange-text[data-v-1ebd09d2] {
  background: #fff;
  cursor: pointer;
  padding: 10px 10px;
  border: 1px solid #ccc;
  width: 100%;
  overflow: hidden;
  font-size: 15px;
}

.vue-daterange-picker[data-v-1ebd09d2] {
  display: block;
}

.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
</style>
